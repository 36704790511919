.agenda-container {
  font-family: "Roboto", sans-serif;
  max-width: 95%;
  margin: 0 auto;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.agenda-title {
  font-size: 42px;
  color: #343434;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 0px;
  font-weight: bold;
  border-bottom: 2px solid #0e94ff;
}

.events-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.date-group {
  margin-bottom: 20px;
}

.date-header {
  font-size: 16px;
  margin-bottom: 10px;
  background-color: #595959;
  color: #ffffff;
  font-weight: 600;
  border-radius: 8px; /* Cantos arredondados para o cabeçalho do dia atual */
  padding: 10px 10px; /* Padding para o cabeçalho do dia */
}

.event-list {
  list-style: none;
  padding: 0;
}

.event-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.event-time-date {
  margin-right: 30px;
}

.event-details {
  display: flex;
  flex-direction: column;
}

.event-expanded-details {
  margin-top: 5px;
  font-size: 12px;
  color: #343434;
}

.loading-message,
.error-message,
.no-events-message {
  color: #343434;
}

.event-list {
  list-style: none;
  padding: 0;
}

.event-item {
  border-bottom: 1px solid #e0e0e0;
  padding: 5px 0;
}

.event-summary {
  color: #222222;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 14px;
}

.event-date {
  margin-left: 5px;
  color: #5f6368;
}

.event-description,
.event-location {
  color: #202124;
  margin: 5px 0;
}

.event-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 8px; /* Cantos arredondados */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box-shadow para um efeito sutil */
  padding: 5px; /* Ajuste de padding para melhor visualização */
  background-color: #fff; /* Fundo branco para destacar o box-shadow */
}

.date-header.today {
  background-color: #22aad373; /* Cor de destaque para o dia atual */
  color: #343434; /* Cor do texto para garantir contraste */
  font-weight: bold;
  border-radius: 8px; /* Cantos arredondados para o cabeçalho do dia atual */
  padding: 10px 10px; /* Padding para o cabeçalho do dia */
}

.event-item:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease; /* Adiciona delay e suaviza a transição */
}

.event-time {
  color: #1a73e8;
  font-weight: bold;
  font-size: 14px;
}

.event-place {
  margin-top: 12px;
  font-weight: 900;
  color: #000000;
}

.event-item.current {
  background-color: #1a73e85f;
  color: white;
}

.show-past-events {
  cursor: pointer;
  color: #1a73e8;
  margin-bottom: 20px; /* Espaço antes da lista de eventos */
}

.event-item:hover {
  transform: scale(1.02);
  transition: all 0.2s ease; /* Adiciona delay na animação */
}

/* Estilos para dias e eventos anteriores */
.date-header.past {
  background-color: #49494979; /* Verde para cabeçalhos de dias anteriores */
}

.event-item.past {
  /* Estilos para eventos passados. Por exemplo: */
  background-color: #bebebe4d; /* Cor de fundo para indicar eventos passados */
}

.navigation-days {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  margin-bottom: 5px;
}

.navigation-arrow-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%; /* Faz o wrapper circular */
  padding: 8px; /* Ajusta o tamanho do círculo */
  transition: 0.2s; /* Suaviza a transição do background e transform */
}

.navigation-arrow-wrapper:active {
  background-color: #2aa0e042; /* Cor do círculo ao clicar */
}

.navigation-arrow {
  font-size: 10px; /* Ajusta o tamanho da flecha */
  color: #000; /* Ajusta a cor (e a "espessura") da flecha */
}

.navigation-date {
  font-size: 14px;
  font-weight: 600;
}

.event-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-summary-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 93%;
}

.attachment-button {
  cursor: pointer;
  margin-left: auto; /* Alinha o botão à direita */
  padding: 5px;
  border: none;
  border-radius: 5px;
  background-color: #0e94ff;
  color: #ffffff;
}

.attachment-button:hover {
  background-color: #4baeff;
}

.search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 20%;
  padding: 5px 5px;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.search-input-agenda {
  border: none;
  font-size: 13px;
  font-weight: 600;
  width: 100%;
  margin-left: 5px;
  color: #343434;
}

.search-input-agenda::placeholder {
  color: #343434;
  font-size: 13px;
  font-weight: 600;
}

.search-icon-agenda {
  font-size: 10px;
  color: #343434;
}
